<template>
  <div id="app">
    <div class="home page">
      <div class="bg-light"></div>

      <img src="~/img/logo.png" alt="" class="logo" />

      <div class="center">
        <div class="bg-ball">
          <div class="arrow"></div>
          <div class="light-op">
            <div class="light-1"></div>
            <div class="light-2"></div>
          </div>
        </div>

        <div class="page1 ">
          <div class="p1-title">
            <!-- <img src="~/img/p1-10.png" alt="" class="title-light-1" />
            <img src="~/img/p1-10.png" alt="" class="title-light-2" /> -->
          </div>
          <img src="~/img/p1-2.png" alt="" class="p1-2" />
          <img src="~/img/p1-3.png" alt="" class="p1-3" />
        </div>

        <div class="page2 dn">
          <div class="box">
            <img src="~/img/p2-14.png" alt="" class="p2-1" />
            <img src="~/img/p2-1.png" alt="" class="p2-2" />
            <div class="line"></div>
            <img src="~/img/p2-2.png" alt="" class="p2-3" />
            <img src="~/img/p2-3.png" alt="" class="p2-4" />
            <img src="~/img/p2-4.png" alt="" class="p2-5" />
            <img src="~/img/p2-5.png" alt="" class="p2-6" />
            <img src="~/img/p2-6.png" alt="" class="p2-7" />
            <img src="~/img/p2-7.png" alt="" class="p2-8" />
            <img src="~/img/p2-8.png" alt="" class="p2-9" />
            <img src="~/img/p2-9.png" alt="" class="p2-10" />
            <img src="~/img/p2-10.png" alt="" class="p2-11" />
            <div class="p2_animate">
              <img src="~/img/p2-11.png" alt="" class="p2-12" />
              <img src="~/img/p2-12.png" alt="" class="p2-13" />
              <img src="~/img/p2-13.png" alt="" class="p2-14" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page page3 dn">
      <div class="center">
        <div class="line"></div>
        <div class="p3-con">
          <img src="~/img/p3-6.png" alt="" class="p3-6" />
          <img src="~/img/p3-7.png" alt="" class="p3-7" />
          <img src="~/img/p3-8.png" alt="" class="p3-8" />
          <img src="~/img/p3-9.png" alt="" class="p3-9" />
        </div>
        <img src="~/img/p3-1.png" alt="" class="p3-1" />
        <img src="~/img/p3-2.png" alt="" class="p3-2" />
        <img src="~/img/p3-3.png" alt="" class="p3-3" />
        <img src="~/img/p3-4.png" alt="" class="p3-4" />
        <img src="~/img/p3-5.png" alt="" class="p3-5" />
        <img src="~/img/p3-10.png" alt="" class="p3-10" />
        <img src="~/img/p3-11.png" alt="" class="p3-11" />
        <img src="~/img/p3-12.png" alt="" class="p3-12" />
        <img src="~/img/p3-13.png" alt="" class="p3-13" />
      </div>
    </div>

    <div class="page4 page dn">
      <div class="center">
        <div class="p4-12"></div>
        <img src="~/img/p4-1.png" alt="" class="p4-1" />

        <div class="p4-3"></div>
        <img src="~/img/p4-2.png" alt="" class="p4-2" />
        <div class="p4-4"></div>
        <img src="~/img/p4-5.png" alt="" class="p4-5" />
        <div class="p4-6"></div>
        <img src="~/img/p4-7.png" alt="" class="p4-7" />
        <div class="p4-8"></div>
        <img src="~/img/p4-9.png" alt="" class="p4-9" />
        <div class="p4-10"></div>
        <img src="~/img/p4-11.png" alt="" class="p4-11" />
      </div>
    </div>

    <div class="page5 page dn">
      <div class="center">
        <div class="p1-title">
          <!-- <img src="~/img/p1-10.png" alt="" class="title-light-1" />
          <img src="~/img/p1-10.png" alt="" class="title-light-2" /> -->
        </div>

        <img src="~/img/p5-1.png" alt="" class="ercode">
        <img src="~/img/p5-2.png" alt="" class="p5-2">
      </div>
    </div>

    <img src="~/img/gg.png" alt="" class="gg">

     <div :class="['biaoqian', isplay ? '' : 'cur']" @click="playAudio"></div>
  </div>
</template>

<script>
import * as gsap from "gsap";

export default {
  name: "App",
  data() {
    return {
      imgArr: [],
      r: window.innerWidth / 750,
      state: 0,
      isplay: true,
    };
  },

  mounted() {
    this.util.init();

    let p1 = new gsap.TimelineLite();
    p1.add(gsap.TweenMax.fromTo( ".page1 .p1-title", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ) );
    p1.add( gsap.TweenMax.fromTo( ".p1-2", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p1.add( gsap.TweenMax.fromTo( ".p1-3", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    // p1.pause();

    let p2 = new gsap.TimelineLite();

    let p2_1 = gsap.TweenMax.fromTo( ".page2 .box", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } );
    let p2_2 = gsap.TweenMax.fromTo( ".p2-2", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut, delay: 0.1 } );
    p2.add([p2_1, p2_2], 0, "start");
    p2.add( gsap.TweenMax.fromTo( ".p2-3", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p2.add( gsap.TweenMax.fromTo( ".p2-4", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p2.add( gsap.TweenMax.fromTo( ".p2-5", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.4", "label_1" );

    let p2_6 = gsap.TweenMax.fromTo( ".p2-6", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } );
    let p2_7 = gsap.TweenMax.fromTo( ".p2-7", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut, delay: 0.4 } );
    let p2_8 = gsap.TweenMax.fromTo( ".p2-8", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut, delay: 0.8 } );
    p2.add([p2_6, p2_7, p2_8], "label_1-=1.5", "start");
    p2.add( gsap.TweenMax.fromTo( ".p2-9", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p2.add( gsap.TweenMax.fromTo( ".p2-10", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );

    p2.add( gsap.TweenMax.fromTo( ".p2-11", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p2.add( gsap.TweenMax.fromTo( ".p2_animate", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p2.add( gsap.TweenMax.fromTo( ".p2-14", 2, { scale: 0.75 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.9" );
    p2.pause();

    let p3 = new gsap.TimelineLite();
    p3.add( gsap.TweenMax.fromTo( ".p3-1", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ) );
    p3.add( gsap.TweenMax.fromTo( ".p3-2", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.4" );
    p3.add( gsap.TweenMax.fromTo( ".p3-3", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.5" );
    p3.add( gsap.TweenMax.fromTo( ".p3-4", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.8" );

    p3.add( gsap.TweenMax.fromTo( ".page3 .line", 1, { height: 0 }, { height: 83 * this.r } ), "-=1" );
    p3.add( gsap.TweenMax.fromTo( ".p3-con", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=0.5" );

    p3.add( gsap.TweenMax.fromTo( ".p3-5", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.5" );
    p3.add( gsap.TweenMax.fromTo( ".p3-10", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.3" );
    p3.add( gsap.TweenMax.fromTo( ".page3 .line", 1, { height: 300 * this.r }, { height: 465 * this.r } ), "-=1" );
    gsap.TweenMax.set(".page3 .line", { height: 0 });

    p3.add( gsap.TweenMax.fromTo( ".p3-12", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ) );
    p3.add( gsap.TweenMax.fromTo( ".p3-11", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1.5" );
    p3.add( gsap.TweenMax.fromTo( ".p3-13", 1.6, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ), "-=1.3" );
    p3.pause();

    let p4 = new gsap.TimelineLite();
    p4.add( gsap.TweenMax.fromTo( ".p4-1", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ) );
    p4.add( gsap.TweenMax.fromTo( ".p4-2", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=1" );
    p4.add( gsap.TweenMax.fromTo(".p4-3", 1, { width: 0 }, { width: 427 * this.r }), "-=1" );
    p4.add( gsap.TweenMax.fromTo( ".p4-12", 1.6, { height: 0 }, { height: 128 * this.r } ), "-=0.8" );

    p4.add( gsap.TweenMax.fromTo( ".p4-5", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=0.8" );
    p4.add( gsap.TweenMax.fromTo(".p4-4", 1, { width: 0 }, { width: 413 * this.r }), "-=1" );
    p4.add( gsap.TweenMax.fromTo( ".p4-12", 1.6, { height: 128 * this.r }, { height: 272 * this.r } ), "-=0.8" );

    p4.add( gsap.TweenMax.fromTo( ".p4-7", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=0.8" );
    p4.add( gsap.TweenMax.fromTo(".p4-6", 1, { width: 0 }, { width: 494 * this.r }), "-=1" );
    p4.add( gsap.TweenMax.fromTo( ".p4-12", 1.6, { height: 272 * this.r }, { height: 427 * this.r } ), "-=0.8" );

    p4.add( gsap.TweenMax.fromTo( ".p4-9", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=0.8" );
    p4.add( gsap.TweenMax.fromTo(".p4-8", 1, { width: 0 }, { width: 427 * this.r }), "-=1" );
    p4.add( gsap.TweenMax.fromTo( ".p4-12", 1.6, { height: 427 * this.r }, { height: 574 * this.r } ), "-=0.8" );

    p4.add( gsap.TweenMax.fromTo( ".p4-11", 1.6, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ), "-=0.8" );
    p4.add( gsap.TweenMax.fromTo(".p4-10", 1, { width: 0 }, { width: 427 * this.r }), "-=1" );
    gsap.TweenMax.set(".p4-12", { height: 0 });
    p4.pause();

    let p5 = new gsap.TimelineLite();
    p5.add(gsap.TweenMax.fromTo( ".page5 .p1-title", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ) );
    p5.add(gsap.TweenMax.fromTo( ".page5 .ercode", 2, { scale: 0 }, { scale: 1, ease: gsap.Expo.easeOut } ),'-=1.2');
    p5.add( gsap.TweenMax.fromTo( ".p5-2", 2, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: gsap.Expo.easeOut } ),'-=1.2' );
    p5.pause();


    let animateList = [p1,p2,p3,p4,p5]

    const next = () => {
      if(index == 5){
        return
      }
      if(index == 1){
        $('.logo,.bg-ball').fadeOut(500);
      }

      if(index != 2){
        $('.page'+index).fadeOut(500);
      }else{
        $('.home').fadeOut(500);
      }
      
      index++;
      $('.page'+index).fadeIn(500);

      animateList[index-1].restart();
    };
    const prve = () => {
       if(index == 2){
         $('.logo,.bg-ball').fadeIn(500);
       }
    
        $('.page'+index).fadeOut(500);
        index--;


        if(index != 2){
         $('.page'+index).fadeIn(500);
       }else{
        $('.home').fadeIn(500);
       }
      animateList[index-1].restart();
    };
    let index = 1;
    $("#app") .on("swipeUp", () => { next(); }) .on("swipeDown", () => { prve(); });
  },
  methods: {
    playAudio() {
      if (this.isplay) {
        this.audio.musicPlay(0);
      } else {
        this.audio.musicPlay(1);
      }
      this.isplay = !this.isplay;
    },
  },
};
</script>


