import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false

import 'css/animate.min.css';
import 'css/customAnimate.css';

import 'css/public.css';
import 'css/index.less';

import * as util from 'js/lib/util'
Vue.prototype.util = util;

import touch from "js/lib/touch.js"
touch($);


import 'vant/lib/index.css';


import { bgAudioFix } from "js/lib/audio.js"

let $audio = new bgAudioFix({
  src: 'https://spdb-yt.h5.yscase.com/music.mp3',
  autoPlay:true
});

Vue.prototype.audio = $audio
// import WechatJSSDK from '@yushuo/wechat-jssdk'
// new WechatJSSDK({
//   title: '浦惠e廷 ｜产品服务，重磅升级',
//   desc: '浦发银行全面助力中小微企业发展',
//   link: 'https://spdb-yt.h5.yscase.com',  
//   imgUrl: 'https://spdb-yt.h5.yscase.com/share.jpg',
//   onSDKReady: function(){
//   },
// });




new Vue({
  render: h => h(App),
}).$mount('#app')
